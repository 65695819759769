import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { getPortal } from './config';
import HandleRegistration from './components/HandleRegistration';
import LoginSuccess from './components/LoginSuccess';
import VerifyEmail from './components/VerifyEmail';

function App() {
  return (
    <Router>
      <Routes>
        {/**
         When a new route is added, it must start with /pennymac/ in order to work with the proxy code.
         **/}
        <Route path="/" element={<VerifyEmail />} />
        <Route path="/pennymac/loginSuccess" element={<LoginSuccess />} />
        <Route path="/pennymac/handleRegistration" element={<HandleRegistration />} />
      </Routes>
    </Router>
  );
}

const portalType = getPortal();

const context = {
  kind: 'multi',
  portal: { key: portalType, name: 'Portal Type' },
  user: { key: 'external', name: 'User Type' },
};

console.log('Initializing with LD context:');
console.log(context);

export default withLDProvider({
  clientSideID: process.env.REACT_APP_LAUNCHDARKLY_CLIENT_ID as string,
  context,
})(App);
