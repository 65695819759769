import Cookies from 'js-cookie';
import React from 'react';
import ReactDOM from 'react-dom';

import { Auth0Provider } from '@auth0/auth0-react';

import App from './App';
import {
  ConnectionType,
  getAuth0Config,
  getPortalFromDomain,
  getPortalFromQuery,
  setPortalCookie,
} from './config';

import './index.css';

const Main = () => {
  const portalParam = getPortalFromQuery();
  const portalFromDomain = getPortalFromDomain();
  if (portalParam !== null) {
    setPortalCookie(portalParam);
  } else if (window.location.pathname === '/') {
    setPortalCookie(portalFromDomain);
  }

  const portalCookie = Cookies.get('portal') || 'power';
  const auth0Config = getAuth0Config(portalCookie);

  // const connectionName =
  //   portalCookie === 'p3'
  //     ? ConnectionType.P3UserMigrationDb
  //     : ConnectionType.PowerPlusUserMigrationDb;

  const connectionName = ConnectionType.UserAccountDb;

  return (
    <React.StrictMode>
      <title>MFA</title>
      <meta name="description" content="MFA pre-registration" />
      <link rel="icon" href="/favicon.ico" />
      <Auth0Provider
        domain={auth0Config.domain}
        clientId={auth0Config.clientId}
        authorizationParams={{
          redirect_uri: `${window.location.origin}/pennymac/loginSuccess`,
          connection: connectionName,
        }}
      >
        <App />
      </Auth0Provider>
    </React.StrictMode>
  );
};

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(<Main />, document.getElementById('root'));

export default Main;
