import Cookies from 'js-cookie';

import { MFA_REGISTRATION_COOKIE_NAME, MFA_SKIP_COOKIE_NAME } from './config';

const setCookie = (name: string, expires: number) => {
  Cookies.set(name, 'true', {
    path: '/',
    expires,
    sameSite: 'Strict',
  });
};

export const setUserHasRegistered = () => {
  setCookie(MFA_REGISTRATION_COOKIE_NAME, 365);
};

export const setUserHasSkipped = () => {
  setCookie(MFA_SKIP_COOKIE_NAME, 1);
};
