import Cookies from 'js-cookie';
import styled from 'styled-components';

import { buildP3RedirectUrl, buildPowerRedirectUrl } from '../config';
import { setUserHasSkipped } from '../utils';

const StyledLink = styled.a`
  color: #444444;
  text-decoration: none;
  margin: auto;
  margin-top: 2rem;

  &:hover {
    text-decoration: underline;
  }
`;

interface LinkComponentProps {
  text: string;
}

interface BaseLinkComponentProps extends LinkComponentProps {
  query?: string;
  onClick?: () => void;
}

const LinkComponent = (props: BaseLinkComponentProps) => {
  const { text, query, onClick } = props;
  const portal = Cookies.get('portal') || 'power';
  const HOSTNAME = typeof document !== 'undefined' ? document?.location?.hostname : 'localhost';

  const redirectUrl = `${
    portal === 'power' ? buildPowerRedirectUrl(HOSTNAME) : buildP3RedirectUrl(HOSTNAME)
  }${query ?? ''}`;
  return (
    <StyledLink
      href="javascript:void(0);"
      onClick={() => {
        if (onClick !== undefined) {
          onClick();
        }
        window.location.href = redirectUrl;
      }}
    >
      {text}
    </StyledLink>
  );
};

export const SkipLinkComponent = (props: LinkComponentProps) => {
  const onClick = () => {
    setUserHasSkipped();
  };

  return (
    <LinkComponent text={props.text} query="?skipMfaRegistration=1" onClick={() => onClick()} />
  );
};

export const ReturnLink = (props: LinkComponentProps) => {
  return <LinkComponent text={props.text} query="?mfaRegistered=1" />;
};
