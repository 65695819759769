import Cookies from 'js-cookie';

export const captchaSiteKey = process.env.REACT_APP_CAPTCHA_SITE_KEY || '';
// const CAPTCHA_SECRET_KEY = process.env.REACT_APP_CAPTCHA_SECRET_KEY || '';
export const mfaBffApiEndpoint =
  process.env.REST_API_URL || process.env.REACT_APP_PREREGISTER_API_ENDPOINT || '';
const stackName = process.env.REACT_APP_STACK_NAME || 'mfa-bff';

export const MFA_REGISTRATION_COOKIE_NAME = 'mfa-registration';
export const MFA_SKIP_COOKIE_NAME = 'mfa-skip';

export enum ConnectionType {
  UserAccountDb = 'User-account-db',
  P3UserMigrationDb = 'P3-User-Migration-Db',
  PowerPlusUserMigrationDb = 'PowerPlus-User-Migration-Db',
  TempUserRegistrationSignup = 'Temp-User-Registration-Signup',
}

export type ENVIRONMENT = 'prd' | 'dev2' | 'dev' | 'qa2' | 'stg';

export enum PortalType {
  P3 = 'p3',
  Power = 'power',
}

/**
 * Function for building the API URL based off the current hostname
 * @returns Base API url for the companion BFF
 * @param hostname
 * @param path
 */
export const buildCompanionApiUrl = (hostname: string, path = ''): string => {
  if (hostname.startsWith('localhost')) {
    return 'localhost:3001';
  }

  const [, , TLD] = hostname.split('.');

  const internalDomain = 'pnmac';
  const publicDomain = 'pennymac';

  const env = getEnvironment(hostname);
  let envSuffix = '';
  if (env !== 'prd') {
    envSuffix = `-${env}`;
  }

  const apiSubdomain = `${stackName}-api${envSuffix}`;

  const domain = env === 'prd' ? publicDomain : internalDomain;

  const apiDomain = [apiSubdomain, domain, TLD].join('.');
  return `https://${apiDomain}/${path}`;
};

export const buildPowerRedirectUrl = (hostname: string) => {
  if (hostname.startsWith('localhost')) {
    return 'https://power.pennymac.com';
  }

  const envSuffix = getEnvironment(hostname);

  if (envSuffix === 'prd') {
    return 'https://power.pennymac.com';
  }
  return `https://power-${envSuffix}.pennymac.com`;
};

export const buildP3RedirectUrl = (hostname: string) => {
  if (hostname.startsWith('localhost')) {
    return 'https://portal.gopennymac.com';
  }

  const envSuffix = getEnvironment(hostname);

  if (envSuffix === 'prd') {
    return 'https://portal.gopennymac.com';
  }
  return `https://portal-${envSuffix}.gopennymac.com`;
};

export const getEnvironment = (hostname: string): ENVIRONMENT => {
  const [subdomain] = hostname.split('.');
  let env = 'prd';
  if (subdomain.includes('-dev2')) {
    env = 'dev2';
  } else if (subdomain.includes('-dev')) {
    env = 'dev';
  } else if (subdomain.includes('-qa2')) {
    env = 'qa2';
  } else if (subdomain.includes('-stg')) {
    env = 'stg';
  }
  return env as ENVIRONMENT;
};

//todo: duplicated from account-ui config.ts
export const getHostName = () => {
  return typeof document !== 'undefined' ? document.location.hostname : 'localhost';
};

//todo: duplicated from account-ui config.ts
export const getPortal = () => {
  const host = getHostName();
  switch (true) {
    case host.includes('gopennymac'):
      return PortalType.P3;
    case host.includes(PortalType.Power):
      return PortalType.Power;
    default:
      return Cookies.get('portal') || PortalType.Power;
  }
};

export const getPortalFromQuery = (): PortalType | null => {
  const params = new URLSearchParams(window.location.search);

  /**
   *  Get the query param set from the email campaign.
   *
   *  This is also set in Auth0:
   *  Auth0 Branding => Email Templates => Redirect To:
   *  {{ application.callback_domain }}/pennymac/handleRegistration?portal={% if application.name == 'P3' %} p3 {% else %} power {% endif %}
   */

  const param = params.get('portal');

  let response: PortalType | null = null;
  if (param === PortalType.P3 || param === PortalType.Power) {
    response = param;
  } else {
    console.error(`Unexpected portal parameter: ${param}`);
  }

  return response;
};

export const getPortalFromDomain = (): PortalType => {
  if (window.location.href.includes('gopennymac')) {
    return PortalType.P3;
  }

  return PortalType.Power;
};

export const setPortalCookie = (portal: PortalType) => {
  Cookies.set('portal', portal, { path: '/', expires: 365, sameSite: 'None', Secure: true });
};

export const getPortalCookie = (): string => {
  return Cookies.get('portal') || 'power';
};

export const getAuth0Config = (portalType: string) => {
  if (portalType === PortalType.P3) {
    return {
      domain: process.env.REACT_APP_AUTH0_DOMAIN || '',
      clientId: process.env.REACT_APP_P3_AUTH0_CLIENT_ID || '',
    };
  }
  //Default to Power config
  return {
    domain: process.env.REACT_APP_AUTH0_DOMAIN || '',
    clientId: process.env.REACT_APP_POWER_AUTH0_CLIENT_ID || '',
  };
};
