import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { useAuth0 } from '@auth0/auth0-react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { CircularProgress } from '@mui/material';

import { ReturnLink } from '../components/SkipLink';
import { setUserHasRegistered } from '../utils';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f4f4f4;
`;

const Body = styled.div`
  padding: 0 40px 40px;
  display: flex;
  flex-direction: column;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  background-color: white;
  width: 400px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 40px;
`;

const StyledHeader = styled.h4`
  margin-top: 1rem;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.235;
  letter-spacing: 0.00735em;
`;

const StyledMessage = styled.p`
  margin-top: 0.5rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  text-align: center;
`;

function LoginSuccess() {
  const { isAuthenticated, isLoading } = useAuth0();
  const portal = Cookies.get('portal') || 'power';
  const [searchParams] = useSearchParams();
  const isVerified = searchParams.get('isVerified');

  useEffect(() => {
    if (isAuthenticated) {
      setUserHasRegistered();
    }
  }, [isAuthenticated]);

  if (isLoading) {
    return (
      <Container>
        <CircularProgress color="primary" />
      </Container>
    );
  }
  const portalName = portal === 'power' ? 'POWER+' : 'P3';

  return (
    <Container>
      <Card>
        {isAuthenticated || isVerified ? (
          <>
            <CheckCircleOutlineIcon style={{ color: '#5cb85c', width: '100px', height: '100px' }} />
            <StyledHeader>You&apos;re All Set!</StyledHeader>
            <StyledMessage>You have successfully set up two-factor authentication.</StyledMessage>
            <Body>
              <ReturnLink text={`Click here to return to ${portalName}`} />
            </Body>
          </>
        ) : (
          <>
            <StyledHeader>You are not logged in.</StyledHeader>
            <StyledMessage>
              Please check your email and continue setting up two-factor authentication.
            </StyledMessage>
          </>
        )}
      </Card>
    </Container>
  );
}

export default LoginSuccess;
