import axios from 'axios';
import Cookies from 'js-cookie';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useAuth0 } from '@auth0/auth0-react';
import { createTheme, ThemeProvider } from '@mui/material';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import { SkipLinkComponent } from '../components/SkipLink';
import { buildCompanionApiUrl, captchaSiteKey } from '../config';

const theme = createTheme({
  palette: {
    primary: {
      main: '#2163e2',
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#635dff',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#635dff',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          height: '54px',
          textTransform: 'none',
          fontSize: '1rem',
        },
      },
    },
  },
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f4f4f4;
`;

const Card = styled.div`
  background-color: white;
  max-width: 400px;
  width: 100%;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;

const Logo = styled.img`
  max-width: 200px;
  margin: auto;
`;

const ErrorText = styled.div`
  color: red;
  margin-top: 12px;
`;

const Header = styled.div`
  padding: 40px 40px 24px;
  display: flex;
  flex-direction: column;
`;

const Body = styled.div`
  padding: 0 40px 40px;
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
`;

const Prompt = styled.div`
  line-height: 1.5;
  text-align: center;
  font-size: 0.9rem;
`;

// Might need to do some forceful styling of the recaptcha here:
const CaptchaWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  > div {
    width: 100% !important;
  }
`;

function VerifyEmail() {
  const navigate = useNavigate();
  const { loginWithRedirect } = useAuth0();
  const { featDisallowMfaSkip } = useFlags();
  const [email, setEmail] = useState<string>('');
  const [recaptchaValue, setRecaptchaValue] = useState<string | null>(null);
  const [isValidated, setIsValidated] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [emailVerified, setEmailVerified] = useState<boolean>(false);

  const portal = Cookies.get('portal') || 'power';

  const handleSubmit = async () => {
    setIsSubmitting(true);

    if (!recaptchaValue) {
      setError('Please verify the captcha before continuing.');
      setIsSubmitting(false);
      return;
    }

    const HOSTNAME = typeof document !== 'undefined' ? document?.location?.hostname : 'localhost';
    const path = buildCompanionApiUrl(HOSTNAME, 'preregister');

    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response = await axios.post<any>(path, {
        email,
        portal,
      });

      if (response.data?.user?.multifactor?.length > 0) {
        navigate('/pennymac/loginSuccess?isVerified=true');
      } else if (response.data?.verified) {
        setEmailVerified(true);
      } else {
        setIsValidated(true);
        setError(null);
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      if (err.response && err.response.status === 404) {
        setError(
          'We did not recognize this email address. Please contact your account administrator.'
        );
      } else {
        setError('An error occurred. Please try again.');
      }
    }
    setIsSubmitting(false);
  };

  if (emailVerified) {
    return (
      <ThemeProvider theme={theme}>
        <Container>
          <Card>
            <Header>
              <Logo
                height="52px"
                src="https://pennymac.atlassian.net/wiki/download/attachments/32771/atl.site.logo?version=2&modificationDate=1642083430057&cacheVersion=1&api=v2"
                alt="PennyMac Logo"
              />
              <Title>Your email is verified</Title>
            </Header>
            <Body>
              <Button
                variant="contained"
                size="large"
                color="primary"
                data-testid="verified-login-button"
                onClick={async () => loginWithRedirect()}
              >
                Click here to login
              </Button>
            </Body>
          </Card>
        </Container>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <Card>
          <Header>
            <Logo
              height="52px"
              src="https://pennymac.atlassian.net/wiki/download/attachments/32771/atl.site.logo?version=2&modificationDate=1642083430057&cacheVersion=1&api=v2"
              alt="PennyMac Logo"
            />
            {!isValidated ? (
              <>
                <Title>Multi-Factor Authentication Setup</Title>
                <Prompt>
                  <p>
                    Beginning <b>January 1, 2024</b> multi-factor authentication will be required
                    for access to <b>{portal === 'power' ? 'POWER+' : 'P3'}</b>.
                  </p>
                  <p>
                    <i>This will only take a few minutes.</i>
                  </p>
                </Prompt>
              </>
            ) : (
              <>
                <Title>Verify your email</Title>
                <Prompt>
                  <p>We&apos;ve sent an email with your code to {email}</p>
                </Prompt>
              </>
            )}
          </Header>
          {!isValidated && (
            <Body>
              <TextField
                fullWidth
                label="Email"
                variant="outlined"
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                style={{ marginBottom: '16px' }}
              />
              <CaptchaWrapper>
                <ReCAPTCHA
                  data-testid={`mock-captcha-element`}
                  sitekey={captchaSiteKey}
                  onChange={(value) => {
                    if (typeof value === 'string') {
                      setRecaptchaValue(value);
                    } else {
                      setRecaptchaValue(null);
                    }
                    setError(null);
                  }}
                />
              </CaptchaWrapper>
              {error && <ErrorText>{error}</ErrorText>}
              <Button
                variant="contained"
                size="large"
                color="primary"
                onClick={handleSubmit}
                style={{ marginTop: '16px' }}
                disabled={isSubmitting}
                component="button"
                type="button"
              >
                {isSubmitting ? 'Submitting...' : 'Continue'}
              </Button>
              {!featDisallowMfaSkip && <SkipLinkComponent text={'Set Up Later'} />}
            </Body>
          )}
        </Card>
      </Container>
    </ThemeProvider>
  );
}

export default VerifyEmail;
