import { useEffect } from 'react';

import { useAuth0 } from '@auth0/auth0-react';

function HandleRegistration() {
  const { isAuthenticated, logout, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      // If user is authenticated, logout and return to this page
      logout({
        logoutParams: {
          returnTo: `${window.location.origin}/pennymac/handleRegistration`,
        },
      });
    } else {
      // If user is not authenticated, redirect to login
      loginWithRedirect();
    }
  }, [isAuthenticated, logout, loginWithRedirect]);

  return null; // This component does not need to render anything
}

export default HandleRegistration;
